import React from 'react';

function Nav(props) {
  return(
    <header>
      <h1>Pomodoro</h1>
      <nav>
        <a onClick={props.showSettings}>settings</a>
      </nav>
    </header>
  );
}

export default Nav;
