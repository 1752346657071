function ClockFace(props) {
  function minutes(timer) {
    return String(Math.floor(timer / 60)).padStart(2, '0');
  }

  function seconds(timer) {
    return String(Math.ceil(timer % 60)).padStart(2, '0');
  }

  return(
    <div className="clockface">
      { `${minutes(props.timer)}:${seconds(props.timer)}` }
    </div>
  );
}

export default ClockFace;
